@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");

html {
  max-width: 100vw;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  overflow-x: hidden;
}
body {
  margin: 0;
  font-family: "Lato", sans-serif;
  background-color: theme("colors.neutral.950");
  max-width: 100vw;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  overflow-x: hidden;
}

/* Hide scrollbar for Chrome, Safari, and Opera */
body::-webkit-scrollbar, html::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}

.hero {
  background: url(./images/hero-bg.svg) top center no-repeat;
  background-size: cover;
}

.footer {
  background: url(./images/footer.svg) top center no-repeat;
  background-size: cover;
}

.cta {
  background: linear-gradient(rgba(2, 2, 2, 0.7), rgba(0, 0, 0, 0.7)),
    url(./images/photo-1497215728101-856f4ea42174-smaller.avif)
      fixed center center;
}

/* Old svg blue: #1e3a8a */
/* New svg orange: #DE8203 */

.stuff {
  color: #DE8203; 
}

.ful {
  color: #00b050;
}

.darkgray {
  color: #2F2F2F;
}